import { buildSerRate } from './serRate'
import { buildSerTownInfo } from './serTownInfo'

export function buildSerZone (zone) {
    return {
        id: zone.id,
        name: zone.name,
        rates: zone.rates.map(buildSerRate),
        serTownId: zone.id_ser_town,
        coordinates: zone.coordinates,
        duplicatedName: zone.duplicatedName,
        town: {
            id: zone.town.id,
            slug: zone.town.slug,
            name: zone.town.name,
            coordinates: zone.town.coordinates,
            hasExactPayment: zone.town.hasExactPayment,
            info: buildSerTownInfo(zone.town.info),
        },
    }
}

export function buildSerZones (zones) {
    return zones.map(zone => {
        return buildSerZone({
            ...zone,
            duplicatedName: zones.filter(item => item.name === zone.name).length > 1,
        })
    })
}
